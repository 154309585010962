var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row maindashboard" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("div", { staticClass: "cardcontents directorMainLayer" }, [
              _c(
                "div",
                { staticClass: "card-header non-label-icon mapheader" },
                [
                  _c(
                    "q-chip",
                    {
                      staticClass: "chip-task",
                      attrs: {
                        square: "",
                        clickable: "",
                        outline: _vm.mapObj.activeTask !== 1,
                        color:
                          _vm.mapObj.activeTask === 1 ? "green-6" : "gray-4",
                        "text-color":
                          _vm.mapObj.activeTask === 1 ? "white" : "white",
                        icon: "directions",
                      },
                      on: { click: () => (_vm.mapObj.activeTask = 1) },
                    },
                    [_vm._v(" " + _vm._s(_vm.setLabel("LBL0000214")) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-more main-header-input main-header-input2",
                    },
                    [
                      _c("c-datepicker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mapObj.activeTask === 1,
                            expression: "mapObj.activeTask===1",
                          },
                        ],
                        attrs: {
                          range: true,
                          appendToBody: false,
                          name: "workPermitPeriod",
                        },
                        on: { datachange: _vm.getMapData },
                        model: {
                          value: _vm.mapObj.taskParam.workPermitPeriod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mapObj.taskParam,
                              "workPermitPeriod",
                              $$v
                            )
                          },
                          expression: "mapObj.taskParam.workPermitPeriod",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "carousel",
                  staticClass: "card-body",
                  staticStyle: { padding: "15px !important" },
                },
                [
                  _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                    ? _c(
                        "q-carousel",
                        {
                          staticClass: "workPermit-carousel",
                          attrs: {
                            swipeable: "",
                            animated: "",
                            arrows: "",
                            "control-type": "regular",
                            "control-color": "grey-6",
                            infinite: "",
                          },
                          model: {
                            value: _vm.mapObj.slide,
                            callback: function ($$v) {
                              _vm.$set(_vm.mapObj, "slide", $$v)
                            },
                            expression: "mapObj.slide",
                          },
                        },
                        _vm._l(_vm.mapObj.maps, function (map, idx) {
                          return _c(
                            "q-carousel-slide",
                            {
                              key: idx,
                              attrs: { name: idx, "img-src": map.mapSrc },
                            },
                            [
                              [
                                _c("div", {
                                  staticClass: "mapTitleDiv",
                                  domProps: {
                                    textContent: _vm._s(map.mapName),
                                  },
                                }),
                                _vm.mapObj.activeTask === 1
                                  ? _vm._l(map.maps, function (item, idx) {
                                      return _c(
                                        "VueDraggableResizable",
                                        {
                                          key: idx,
                                          ref: "markImage",
                                          refInFor: true,
                                          staticClass: "mainMarkImage",
                                          attrs: {
                                            resizable: false,
                                            parent: true,
                                            draggable: false,
                                            x:
                                              item.locationXcoordinate *
                                              _vm.mapRate,
                                            y:
                                              item.locationYcoordinate *
                                              _vm.mapRate,
                                            w: 40,
                                            h: 40,
                                            grid: [20, 20],
                                          },
                                        },
                                        [
                                          _c("q-icon", {
                                            staticClass: "blinking",
                                            attrs: { name: "push_pin" },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "markImage-abbreviation-parent",
                                              style: {
                                                width: "341px",
                                                left: _vm.getLeft(item),
                                                top: _vm.getTop(item),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "markImage-abbreviation",
                                                },
                                                [
                                                  _c(
                                                    "transition",
                                                    {
                                                      attrs: {
                                                        name: "mark-list",
                                                        tag: "div",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: true,
                                                              expression:
                                                                "true",
                                                            },
                                                          ],
                                                        },
                                                        _vm._l(
                                                          item.resultTypes,
                                                          function (
                                                            resultType,
                                                            idx
                                                          ) {
                                                            return _c(
                                                              "q-banner",
                                                              {
                                                                key: idx,
                                                                class: [
                                                                  idx % 2 === 1
                                                                    ? "bg-grey-3"
                                                                    : "bg-grey-1",
                                                                  "markImage-abbreviation-banner-detail",
                                                                ],
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "avatar",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "q-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "text-red",
                                                                                attrs:
                                                                                  {
                                                                                    name: _vm.workPermitIconName(
                                                                                      resultType
                                                                                    ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      resultType.sopWorkTypeName
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("q-badge", {
                                                                  attrs: {
                                                                    color:
                                                                      _vm.workPermitbadgeColor(
                                                                        resultType.swpStepName
                                                                      ),
                                                                    label:
                                                                      resultType.swpStepName,
                                                                  },
                                                                }),
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "q-ml-sm",
                                                                  attrs: {
                                                                    outline: "",
                                                                    round: "",
                                                                    dense: "",
                                                                    color:
                                                                      "red",
                                                                    size: "9px",
                                                                    label: "",
                                                                    icon: "add",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openWorkPermit(
                                                                          resultType
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "caption-work",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          resultType.workSummary
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    })
                                  : _vm._e(),
                              ],
                            ],
                            2
                          )
                        }),
                        1
                      )
                    : _c("el-empty", {
                        attrs: {
                          "image-size": 435,
                          description: _vm.$comm.getLangMessage("MSG0000122"),
                        },
                      }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-notice-popup"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }