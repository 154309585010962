<template>
  <div>
    <div class="row maindashboard">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="cardcontents directorMainLayer">
          <div class="card-header non-label-icon mapheader">
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==1"
              :color="mapObj.activeTask===1 ? 'green-6' : 'gray-4'" 
              :text-color="mapObj.activeTask===1 ? 'white' : 'white'" 
              icon="directions"
              @click="() => mapObj.activeTask = 1">
              <!-- 작업허가서 -->
              {{setLabel('LBL0000214')}}
            </q-chip>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                v-show="mapObj.activeTask===1"
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
            </div>
          </div>
          <div class="card-body" ref="carousel" style="padding: 15px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
              infinite
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <!-- 작업허가서 -->
                  <template v-if="mapObj.activeTask===1">
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :resizable="false"
                      :parent="true"
                      :draggable="false"
                      :x="item.locationXcoordinate * mapRate"
                      :y="item.locationYcoordinate * mapRate"
                      :w="40 " :h="40"
                      :grid="[20, 20]"
                    >
                      <q-icon name="push_pin" class="blinking" />
                      <div class="markImage-abbreviation-parent"
                        :style="{ 
                          width: '341px',
                          left: getLeft(item),
                          top: getTop(item),
                        }">
                        <div class="markImage-abbreviation">
                          <transition name="mark-list" tag="div">
                            <div v-show="true">
                              <q-banner v-for="(resultType, idx) in item.resultTypes"
                                :key="idx"
                                dense 
                                :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                <template v-slot:avatar>
                                  <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                                </template>
                                {{resultType.sopWorkTypeName}}
                                <q-badge :color="workPermitbadgeColor(resultType.swpStepName)" :label="resultType.swpStepName" />
                                <q-btn class="q-ml-sm" outline round dense color="red" size="9px" label="" icon="add" @click="openWorkPermit(resultType)" />
                                <div class="caption-work">
                                  {{resultType.workSummary}}
                                </div>
                              </q-banner>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </VueDraggableResizable>
                  </template>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="435"
              :description="$comm.getLangMessage('MSG0000122')"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import mixinConvert from '@/js/mixin-template-convert'
export default {
  name: 'indexPartner',
  mixins: [mixinConvert],
  components: { 
    VueDraggableResizable,
  },
  props: {
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      mapObj: {
        activeTask: 1,
        envUrl: '',
        url: '',
        taskParam: {
          workPermitPeriod: [],
        },
        maps: [],
        slide: 0,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 1.3
    },
  },
  methods: {
    init() {
      // 지도
      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.envUrl = selectConfig.main.env.url

      this.getMapPriodParam();
      this.getMapData();
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
      },);
    },
    getMapData() {
      let _height = 800 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.mapObj.maps = _result.data;
          this.$_.forEach(this.mapObj.maps, map => {
            if (map.maps && map.maps.length > 0) {
              this.$_.forEach(map.maps, item => {
                this.$set(item, 'isHover', false)
              });
            }
            if (map.otherMaps && map.otherMaps.length > 0) {
              this.$_.forEach(map.otherMaps, item => {
                this.$set(item, 'isHover', false)
              });
            }
          })
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);
        }
      },);
    },
    /* eslint-disable no-unused-vars */
    getWidth(item) {
      let width = 0;
      this.$_.forEach(item.resultTypes, resultType => {
        width += (resultType.sopWorkTypeName.length * 10) + 60
      })
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'engineering';
          break;
        case 'SPT0000005': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐공간
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 정전
          icon = 'flash_off';
          break;
        case 'SSWT000010': // 방사선
          icon = 'warning';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 중장비
          icon = 'agriculture';
          break;
      }
      return icon;
    },
    workPermitbadgeColor(swpStepName) {
      let icon = '';
      switch(swpStepName) {
        case '승인완료':
          icon = 'light-blue';
          break;
        case '연장':
          icon = 'amber';
          break;
        case '작업완료':
          icon = 'light-green';
          break;
      }
      return icon;
    },
    setStyleTaskArea(mp) {
      let style = {
        transform: 'translate(' + String(mp.x * this.mapRate) + 'px, ' + String(mp.y * this.mapRate + 30) + 'px)',
        'z-index': 1,
        'user-select': 'auto',
        width: '1px',
        height: '1px',
        position: 'absolute'
      }
      if (mp.isHover) {
        style['z-index'] = 7
      }
      return style;
    },
    imgHeight(map) {
      return String(map.ratio * 800) + 'px'
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setChart() {
      setTimeout(() => {
        this.yearOcuurChart.chartWidth = '90%';
      }, 200);
      this.$refs['yearOcuurChart'].refresh();
    },
  }
};
</script>
<style lang="sass">
.no-padding
  padding: 0px 0px 6px 0px !important
.maindashboard .cardcontents
  box-shadow: 0 !important
  border: 1px solid rgba(0,0,0,0.12) !important
  border-radius: 10px !important
  margin-top: 6px
  margin-bottom: 6px
  margin-right: 6px
  .card-body
    padding: 10px !important
    border-radius: 0 0 10px 10px !important
    background: #fff
  .card-header:before
    font-family: "Material Icons"
    content: "\e876"
  .card-header
    border-bottom-width: 0px !important
    border-radius: 10px 10px 0 0 !important
    height: 40px
    color: #0072c6
    background: #f8f8f9
    display: inline-block
    padding-top: 8px
    width: 100%
    .card-more
      float: right !important
      cursor: pointer
      color: #d2d2d2
    .card-more:hover
      color: #1ab394
.main-header-input
  margin-top: -5px
  padding-right: 5px
.main-header-input2
  margin-top: -5px
  padding-right: 5px
  width: 240px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.cardcontents .card-body.nopadgrid
  padding-top: 0px !important
.btn-fixed-width-height
  width: 95%
  margin: 3px !important
  pointer-events: none

.directorMainLayer
  .workPermit-carousel
    border-radius: 10px
    height: var(--map-height) !important
    .q-carousel__slide
      width: calc(var(--map-ratio) * var(--map-height)) !important
    .q-carousel--padding, .q-carousel__slide
      padding: 0 !important
    .q-carousel__arrow
      transition: all 0.5s
      opacity: 0.2
    .q-carousel__arrow:hover
      transition: all 0.5s
      opacity: 0.8
    .q-carousel__slides-container
      .q-panel.scroll
        overflow: hidden !important


.directorMainLayer
  .caption-work2
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #fff
    padding: 10px
  .caption-work
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #555
    margin-top: 4px

.directorMainLayer
  .mainmapstart
    float: right
    margin-right: 20px

.directorMainLayer
  .mainMarkImage
    padding: 0px !important
    text-align: center
    font-size: 20px
    height: 30px !important
    position: absolute
    padding: 16px
    color: #C10015
    z-index: 3 !important
    // background: rgba(0,0,0,0.47)
    .markImage-abbreviation-parent
      // width:240px
      text-align: start
      position: absolute
      .markImage-abbreviation
        cursor: pointer
        display: inline-block
        // min-width:60px
        // max-width:250px
        width: auto
        border-left: 1px solid #757575
        border-right: 1px solid #757575
        border-top: 1px solid #757575
        border-bottom: 1px solid #757575
        border-radius: 5px
        .markImage-tran
          border-radius: 5px
        .markImage-abbreviation-banner
          border-right: 1px solid #757575
          display: inline-block
          text-align: center
          min-width:60px
          padding: 1px !important
          border-radius: 5px 0px 0px 5px
          i
            font-size: 20px
          div
            font-size: 12px !important
        .markImage-abbreviation-banner.last-banner
          border-right: 0
          border-radius: 0px 5px 5px 0px
        .markImage-abbreviation-plus
          display: inline-block
          text-align: center
          min-width:40px
        .markImage-abbreviation-banner-detail
          border-radius: 5px
          .q-banner__avatar i
            font-size: 20px !important
          .q-banner__content
            font-size: 12px !important

.directorMainLayer
  .markImage-abbreviation > div
    -webkit-animation: fadeInFromNone 0.1s ease-out
    -moz-animation: fadeInFromNone 0.1s ease-out
    -o-animation: fadeInFromNone 0.1s ease-out
    animation: fadeInFromNone 0.1s ease-out


.main-map-card 
  border-radius: 10px !important
  border-width: 0px !important
  background-color: #161d31 !important
  .q-tab--inactive
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
  .q-tab--active
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
    background-color: #fff !important
    color:  #161d31 !important
  .q-tab__indicator
    display: none !important
  .customCardbody
    margin: 0px !important
    .main-card-head-text
      font-weight: 600
      font-size: 0.9rem
      line-height: 26px
  .cardhead
    height: 41px !important
    background-color: #343d55 !important
    color: #fff !important
    padding-top: 5px !important
    font-size: 1.1em !important
  .customCardbody
    background-color: #283046 !important
    border-radius: 0 0 10px 10px !important
    padding-top: 10px !important
  .q-separator--horizontal
    display: none !important
  .mainDashboardTab
    .q-tab-panel
      display: table
      width: 100%
    .q-tab__label
      font-size: 1.0em !important
      font-weight: 600
  .mainDashboardTabS
    .q-tab__label
      font-size: 1.1em !important
  .maintabTable
    width: 100%
    display: inline-flex
    color: #fff !important
  .maintabTableTop
    border-top: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintabTd
    font-size: 1.1em !important
    padding: 8px 5px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
  .maintabTr
    font-size: 1.1em !important
    padding: 8px 5px
    width: 100%
    background-color: #161d31 !important
  .maintabTd7
    width: 70%
  .maintabTd6
    width: 60%
  .maintabTd5
    width: 50%
  .maintabTd4
    width: 40%
  .maintabTd3
    width: 30%
    text-align: right
  .maintabTd2
    width: 20%
  .maintabTd1
    width: 10%
  .maintabTdgreen
    color: #28c76f !important
    font-size: 1.05em !important
    text-align: center
  .maintablebody
    height: 250px
    background-color: #343d55 !important
    border-radius: 0 0 10px 10px !important
    overflow-y: auto
    border-bottom: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintablebody2
    height: 300px !important
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.my-class-partner {
  /* background-color: #9E9E9E; */
  border: 2px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(40, 48, 70, 0.2);
  border-radius: 10px;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
  color: black;
  cursor: pointer;
  /* z-index: 1 !important; */
}
.my-class-partner div {
  width: 100%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  background-color: rgba(40, 48, 70, 0.6);
}
.my-active-class {
  /* opacity: 0.5; */
  background-color: rgba(115,103,240,0.5);
  /* color:azure; */
}
.chip-task {
  margin-left: 8px !important;
}
.app-main__inner .task-detail {
  z-index: 5;
  width:250px;
  position: relative;
}
.app-main__inner .task-detail .q-banner {
  background-color: #283046;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0px !important;
  color: #fff !important;
}
.app-main__inner .task-detail .q-banner .col-auto {
  padding: 0px !important;
}
.mainmapbannerTitle {
  width: 100%;
  color: #fff;
  background-color: #343d55;
  padding: 10px !important;
  border-radius: 3px;
  font-size: 0.9em !important;
}
.open-task {
  left: 100%; 
  width: 80%;
  transform: translate(-100%, 0);
  position: absolute;
  z-index: 1000;
  padding: 10px !important;
  border-radius: 15px !important;
  background: #fff;
}
.maintablebody::-webkit-scrollbar {
  width: 10px;
}
.maintablebody::-webkit-scrollbar-track {
  background: #343d55 !important;
  border-radius: 0 0 10px 0;
}

.maintablebody::-webkit-scrollbar-thumb {
  background-color: #161d31 !important;
  background-image: linear-gradient(315deg, #161d31 0%, #161d31 74%);
}
.link-line {
  text-decoration: underline;
  cursor: pointer;
}
.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #5e5f61 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 9999;
  font-size: 12px;
}
</style>